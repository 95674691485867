import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Bio from "../components/bio"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0
  return (
    <Layout title={siteTitle}>
      <SEO
        title="Posts"
        keywords={[`machine learning`, `ai`, `blog`, `stories`, `poetry`]}
        image={`${data.site.siteMetadata.url}${data.ogimage.childImageSharp.fluid.src}`}
        description="Eclectic Beams - Writing the dreams of an insane AI"
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
              loading="lazy"
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query IndexPosts($crop: ImageCropFocus) {
    ogimage: file(
      relativePath: { eq: "photos-hobby-JjGXjESMxOY-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 920, maxHeight: 475, cropFocus: $crop) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    allMarkdownRemark(
      filter: { fields: { isFuturePost: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 920, maxHeight: 475, cropFocus: $crop) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
